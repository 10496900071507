import React, { Component } from 'react';
import { withController } from 'react-scroll-parallax';
import PropTypes from 'prop-types'


const { detect } = require('detect-browser');
const browser = detect();

class ParallaxImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
        id: this.props.id
    }
  }


  static propTypes = {
    parallaxController: PropTypes.object,
  };

  componentDidMount() {
    const { parallaxController } = this.props;
    parallaxController.update();
  }


  render() {
   

    return (
     <div id={this.state.id}></div>
    );
  }
}

export default withController(ParallaxImage);
import React, { Component } from 'react';
import poster from '../../assets/poster.png';
import { ParallaxBanner } from 'react-scroll-parallax';
import seperator from '../../assets/section_break.png';
import Footer from '../global-components/footer';
import ParallaxImage from '../global-components/image';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';


class SectionThree extends Component {

  handleClick = () => {
    scroll.scrollToTop(100);
  };

  render() {
    return (
      <div className="container" id="section-three">
      <div className="seperator seperator-five">
          <img src={seperator} alt="seperator" />
        </div>
        <div id="section-three-header"><h2>YOU CAN HELP</h2></div>
        <div className="parallax-container poster-container">
        {/* <img id="poster" src={poster} alt="poster" /> */}
          {/* <ParallaxBanner
            layers={[
              {
                children: <ParallaxImage id={'poster'}></ParallaxImage>,
                amount: -0.2,
              },

            ]}
            style={{
              height: '100vh',
            }}
          >
          </ParallaxBanner> */}
        </div>
        <ParallaxImage id={'poster-static'}></ParallaxImage>
        <div id="section-three-text">
          <p>So much has been learned on the way to completing this extraordinary aircraft and with your help, we can roll it out to the community as the Community Heritage Aviation Project.<br></br><br></br>
          You can have immediate impact by donating to our project or you can sign up for regular payments and become a part of this unique community.<br></br><br></br>
          If you have skills you think will be useful...be it PR, Accountancy, Project Management, Social Media, or good old joinery and metal working skills, then join us as a volunteer and be part of this amazing team.<br></br><br></br>
          And be around a FLYING WW1 aircraft!
            </p>
            <a href="mailto:mike@strutteraviation.com" target="_blank" className="volunteer-button">VOLUNTEER</a>
            <br></br>
            <p>Or if you can't donate your time, feel free to make a monetary contribution:</p>
            <a href="https://localgiving.org/charity/savesophie/" target="_blank" className="donate-button">DONATE</a>
        </div>
        
        {/* <div className="back-to-top"><a onClick={this.handleClick}>BACK TO TOP</a></div> */}
      </div>
    );
  }
}

export default SectionThree;
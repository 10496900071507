import React, { Component } from 'react';
import logo from '../../assets/logo_colour2.png'
import twitter from '../../assets/twitter_icon.png'
import instagram from '../../assets/insta_icon.png'
import youtube from '../../assets/youtube_icon.png'
import facebook from '../../assets/facebook_icon.png'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "../../pages/home"
import About from "../../pages/about"
import Privacy from "../../pages/privacypolicy"
import NoMatch from "../../pages/nomatch"
import Footer from "./footer"
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class NavigationBar extends Component {
  constructor(props) {
    super(props)
  }

  componentWillUpdate() {
    scroll.scrollTo(0, {
      duration: 0
    });
  }

  render() {
    return (
      <div>
        <Router>
          <div className="nav">
            <a href="/" className="nav-link" to="/">
              <img id="nav-logo" src={logo} alt="Hanger 32" accessKey="1" />
            </a>
            <div id="nav-social">
              {this.props.link}
              <a href="https://localgiving.org/charity/savesophie/" title="Donate today!" target="_blank" className="donate-button">DONATE</a>
              <a href="https://twitter.com/struttertherep?lang=en-gb" target="_blank" title="Hanger 32 on Twitter" className="nav-link" to="/">
                <img className="" id="nav-twitter" src={twitter} alt="Twitter" />
              </a>
              <a href="https://en-gb.facebook.com/strutterapss/" target="_blank" title="Hanger 32 on Facebook" className="nav-link" to="/">
                <img className="" id="nav-facebook" src={facebook} alt="Facebook" />
              </a>
              {/* <img className="social-icon" id="nav-instagram" src={instagram} alt="instagram" />
              <img className="social-icon" id="nav-youtube" src={youtube} alt="youtube" /> */}
            </div>
          </div>
          <div id="nav-paths">
            {/* <Route exact path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/about/" component={About} />
            <Route path="/volunteering/" component={About} /> */}

            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/about/" component={About} />
              <Route path="/volunteering/" component={About} />
              <Route path="/privacy-policy/" component={Privacy} />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default NavigationBar;
import React, { Component } from 'react';
import plane from '../../../assets/plane_withshadow_new.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import { withController } from 'react-scroll-parallax';
import { Animated } from "react-animated-css";
import ReactSnapScroll from 'react-snap-scroll';
import ParallaxProvider from 'react-scroll-parallax/cjs/components/ParallaxProvider';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import scrollIcon from '../../../assets/scroll_icon.png'
import seperator from '../../../assets/section_break.png';


class SlideOne extends Component {

  constructor(props) {
    super(props)
    this.state = {
      planeShow: true,
      notShown: true,
      showText: false,
      isMobile: this.props.isMobile,
      isIE: this.props.isIE
    }
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset >= window.innerHeight * 1.3 && this.state.notShown) {
      this.setState({
        planeShow: false,
        notShown: false
      })
    }
    if (window.pageYOffset > window.innerHeight / 100 * 30) {
      this.setState({
        showText: true
      })
    }
  }


  render() {
    var planeImage;
    var text;
    if (this.state.planeShow && this.state.notShown && !this.state.isMobile) {
      planeImage = <img id="big-plane-parallax" src={plane} alt="plane" />
    } else {
      planeImage = <div></div>
    }

    if (this.state.showText) {
      text = <div className="fade-in-fast slide-text" id="slide-one-text">
        <h2>THE STRUTTER</h2>
        <p>Back in 2000, a team of feisty pensioners started building the Strutter. A brand new, authentic new-build of a lesser known, but important aircraft that defended East Lothian and Edinburgh from the ‘Zeppelin Scourge’ and from the U-boat menace.
        </p>
      </div>
    }
    return (
<div><div className="seperator seperator-one">
          <img src={seperator} alt="seperator" />
        </div>
    {/* <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}> */}
      <div id="slide-one-container" className="plane-container">
       
      <div className="slide-overlay"></div>
        {text}
        <ParallaxBanner
          layers={[
            {
              children: planeImage,
              amount: -0.4,
            },

          ]}
          style={{
            height: '100vh',
          }}
        >
        </ParallaxBanner>
        {/* <div className="scroll-container">
                   <a onClick={this.handleClick}><img id="section-two-scroll-icon" src={scrollIcon} alt="scroll-down" /></a>
                 </div> */}
        <div className="seperator seperator-one">
          <img src={seperator} alt="seperator" />
        </div>

      </div>
      {/* </Animated> */}
      </div>

    )
  };
}

export default SlideOne;
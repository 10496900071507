import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import SectionOne from '../components/home-components/section-one';
import SectionTwo from '../components/home-components/section-two';
import SectionThree from '../components/home-components/section-three';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { withController } from 'react-scroll-parallax';
import PropTypes from 'prop-types'
import { throws } from 'assert';
import Footer from '../components/global-components/footer';
import slideTwoBackground from '../assets/blueprints_bkgd.png';
import slideThreeBackground from '../assets/history_bkgd.png';
import slideFourBackground from '../assets/warehouse.png';
import slideFiveBackground from '../assets/hangar.png';
import paperBackground from '../assets/paper_style1.png';
import paperSmall from '../assets/paper6.png';

const { detect } = require('detect-browser');
const browser = detect();

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewHeight: window.innerHeight,
      viewWidth: window.innerWidth,
      isMobile: false
    }

    this.handleResize = this.handleResize.bind(this);
  }


  static propTypes = {
    parallaxController: PropTypes.object,
  };

  componentDidMount() {
    const { parallaxController } = this.props;
    parallaxController.update();

    window.addEventListener('resize', this.handleResize);

    if(window.innerWidth < 750){
      this.setState({
        isMobile:true
      })
    }
    document.title = "Sopwith 1-1/2 Strutter | Hangar 32";
  }

  handleResize(){

    if(window.innerWidth<750 && this.state.isMobile==false){
    // this.setState({
    //   viewHeight: window.innerHeight,
    //   viewWidth: window.innerWidth,
    //   isMobile:true
    // })
    window.location.reload();
  }else if(window.innerWidth>750 && this.state.isMobile==true){
    // this.setState({
    //   viewHeight: window.innerHeight,
    //   viewWidth: window.innerWidth,
    //   isMobile:false
    // })
    window.location.reload();
  }
}

  render() {
    var container;

    //Preload backgrounds to avoid any delay when they came into view
    var preLoadOne = <img src={slideTwoBackground} style={{opacity:0, position:"absolute"}}></img>;
    var preLoadTwo = <img src={slideThreeBackground} style={{opacity:0, position:"absolute"}}></img>;
    var preLoadThree = <img src={slideFourBackground} style={{opacity:0, position:"absolute"}}></img>;
    var preLoadFour = <img src={slideFiveBackground} style={{opacity:0, position:"absolute"}}></img>;
    var preLoadFive = <img src={paperBackground} style={{opacity:0, position:"absolute"}}></img>;
    var preLoadSix = <img src={paperSmall} style={{opacity:0, position:"absolute"}}></img>;

    if (browser.name === "ie" || window.innerHeight < 600 || window.innerWidth < 750) {
      container = "section-container section-container-ie";
    } else {
      container = "section-container";
    }

    return (
      <Animated animationIn="fadeIn" isVisible={true}>
      <div id="preload">
        {preLoadOne}
        {preLoadTwo}
        {preLoadThree}
        {preLoadFour}
        {preLoadFive}
        {preLoadSix}
      </div>
        <div className={container}>
          <div id="home">
            <SectionOne></SectionOne>
            <SectionTwo></SectionTwo>
            <SectionThree></SectionThree>
          </div>
        </div>
        <Footer></Footer>
      </Animated>
    );
  }
}

export default withController(Home);
import React, { Component } from 'react';
import imageTwo from '../../assets/image2.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import imageOne from '../../assets/image1.png'

class SectionTwo extends Component {
    
    render() {
        return (
            <div className="about-section-two">

                <div className="about-text-parallax-container">

                  <div id="about-info">
                        <div id="about-text">
                            <h2>WHAT DO WE DO?</h2>
                            <p>We tackle age related problems and commemorate the heritage of Great War aviation by hand- building WW1 aircraft.</p>
                            <h2>HOW DO WE DO IT?</h2>
                            <p>We have a large workshop, 30 working volunteers and a great deal of experience.</p>
                            <p>Our work is divided into 3 areas:</p>
                            <ul>
                                <li>Project work: Building ww1 aircraft <span class="line">from the original plans and flying them</span></li>
                                <li>Training: We pass on our skills and experience to the community</li>
                                <li>Heritage: We talk about the history of <span class="line"> the defence of East Lothian and Edinburgh </span></li>
                            </ul>
                            <p>We believe that:</p>
                            <ul>
                                <li>Working collectively is good</li>
                                <li>Working inter-generationally is good</li>
                                <li>Coming together for leisure activities is good</li>
                                <li>Telling the untold story of the defence of East Lothian and Edinburgh by the men and women of the Royal Naval Air Service is good</li>
                            </ul>
                          </div>

                    </div>

                </div>
                <div className="about-parallax-container right-padding">
                <img src={imageTwo} className="about-image" id="about-image-one" alt="image-two" />
                <img src={imageOne} className="about-image" id="about-image-two" alt="image-one" />
                </div>

            </div>
        )
    }
}

export default SectionTwo;
import React, { Component } from 'react';
import plane from '../../../assets/plane.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import { withController } from 'react-scroll-parallax';
import { Animated } from "react-animated-css";
import scrollIcon from '../../../assets/scroll_icon.png'
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import seperator from '../../../assets/section_break.png';
import numberOne from '../../../assets/icon_number1.png';
import numberTwo from '../../../assets/icon_number2.png';
import numberThree from '../../../assets/icon_number3.png';


class SlideFour extends Component {

  constructor(props){
    super(props)
    this.state = {
      isIE: this.props.isIE
  }
  }


  render() {
    return (
        <div id="slide-four-container" className="plane-container">
        <div className="slide-overlay"></div>
          <div className="fade-in-fast slide-text" id="slide-four-text">
            <h2>NOT A LOT OF PEOPLE KNOW</h2>
              {/* <div className="next-steps"><img src={numberOne} className="number-icon"/><p>For us to be able to offer the training and experience of heritage aircraft building that we want, our shed needs to be made fit for purpose. So our first phase will be to finish and fly the Strutter and to raise the money to upgrade our shed.</p></div>
              <div className="next-steps"><img src={numberTwo} className="number-icon"/><p>We will further develop and broaden our outreach programmes and take young people on to a series of carefully designed training courses. We will continue to build heritage aircraft.</p></div>
              <div className="next-steps"><img src={numberThree} className="number-icon"/><p>Establish Hanger 32!</p></div> */}
          </div>
          <ParallaxBanner
            layers={[
              {
                amount: -0.0,
              },

            ]}
            style={{
              height: '100vh',
            }}
          >
          </ParallaxBanner>
                 <div className="seperator seperator-one">
          <img src={seperator} alt="seperator"/>
        </div>
        </div>
    )
  };
}

export default SlideFour;
import React, { Component } from 'react';
import './App.css';
import NavigationBar from './components/global-components/nav';
import Footer from './components/global-components/footer';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { ParallaxProvider } from 'react-scroll-parallax';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import logo from './assets/logo_colour2.png'
import { noAuto } from '@fortawesome/fontawesome-svg-core';


class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      home: true
    }

    this.isNotHome = this.isNotHome.bind(this)
    this.isHome = this.isHome.bind(this)
    this.scrollToTop = this.scrollToTop.bind(this)
  }



  componentDidMount() {

    var path = window.location.protocol + '//' + window.location.host + '/about';
    if (window.location.href == path) {
      this.isNotHome()
    }
    console.log(window.location.href)
  }

  isNotHome() {
    if (!this.home) {
      this.setState({
        home: false
      })
      this.scrollToTop();
    }
  }

  isHome() {
    if (!this.home) {
      this.setState({
        home: true
      })
      this.scrollToTop();
    }
  }

  scrollToTop() {
    scroll.scrollToTop({
      duration: 0,
    });
  }

  checkCookieCompliance(){
      //Set to dismiss notification
      if (document.cookie.split(';').filter((item) => {
          return item.includes('consentCookie=true')
      }).length) {
          this.onAccept();
      }
      //Set to deny cookies
      else if (document.cookie.split(';').filter((item) => {
          return item.includes('consentCookie=false')
      }).length) {
          this.onDelete();
      }
      //Not set - running
      else{
          // this.onAccept();
      }
  }

  deleteCookie(name, domain) { 
      document.cookie = name+"=;expires=Mon, 01 Jan 2016 00:00:00 UTC; path=/; domain="+domain+";";
  }

  onAccept(){
      //Run Google Analytics
      ReactGA.initialize('UA-167305403-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
      ReactGA.set({ anonymizeIp: true });
     
  }

  onDelete(){
      this.deleteCookie('_ga', this.cookieURL);
      this.deleteCookie('_gat', this.cookieURL);
      this.deleteCookie('_gid', this.cookieURL);
  } 

  render() {
    var link;
    if (this.state.home) {
      link = <Link onClick={this.isNotHome} className="nav-link" to="/about">ABOUT</Link>
    } else {
      link = <Link onClick={this.isHome} className="nav-link" to="/">HOME</Link>
    }
    return (
      <div>
      <ParallaxProvider>
        <div >
          <NavigationBar link={link}></NavigationBar>
        </div>
      </ParallaxProvider>
      {/* <Footer></Footer> */}

        <CookieConsent
            location="bottom"
            cookieName="consentCookie"
            buttonText="ACCEPT"
            style={{ 
                background: "#000", 
                opacity: "0.95",  
                animation: "1.5s fadeInText",  
                animationFillMode: "forwards", 
                zIndex: "99999",
                height: "300px",
                width: "500px",
                position: "fixed",
                left: "50%",
                marginLeft: "-250px",
                top: "50%",
                marginTop: "-150px"
            }}
            buttonStyle={{ 
                backgroundColor: "#8AA5A7", 
                color: "#FFF", 
                fontWeight: "bold", 
                textTransform: "uppercase",  
                fontSize: "14px", 
                padding: "10px 20px", 
                borderRadius: "4px",
                display: "block",
                margin: "0 auto",
                width: "250px"
            }}
            enableDeclineButton={true}
            declineButtonClasses='declineButton'
            declineButtonText="Decline Cookies"
            flipButtons={true}
            declineButtonStyle={{
                // backgroundColor: "none", 
                fontWeight: "normal", 
                textTransform: "uppercase",  
                fontSize: "14px",
                color: "#FFF",
                background: "#000", 
                opacity: "0.95",
                display: "block",
                margin: "10px auto 0",
                width: "250px"
            }}
            buttonWrapperClasses="buttonWrapper"
            expires={365}
            // acceptOnScroll={true}
            // acceptOnScrollPercentage={0}
            // onAccept={ ({ acceptedByScrolling }) => {
            //     if (acceptedByScrolling) {
            //         this.checkCookieCompliance();
            //         // triggered if user scrolls past threshold
            //     //   this.runGA();
            //         alert("Accept was triggered by scrolling");
            //         ReactGA.initialize('UA-7464175-1');
            //         ReactGA.pageview(window.location.pathname + window.location.search);
            //     } else {
            //         this.checkCookieCompliance();
            //         // this.runGA();
            //         alert("Accept was triggered by clicking the Accept button");
            //         ReactGA.initialize('UA-7464175-1');
            //         ReactGA.pageview(window.location.pathname + window.location.search);
            //     }
            // }}
            onAccept={ () => {
                this.checkCookieCompliance();
                this.onAccept();
            }}
            onDecline={() => {
                this.checkCookieCompliance();
                this.onDelete();
            }}
        >
            <img src={logo} alt="Hanger 32" style={{margin: "0 auto", display: "block", width: "20%"}} />
            <div className="cookieInfo" style={{padding: "15px", textAlign: "center"}}>This website use cookies to ensure you get the best experience. <br/><a href="/privacy-policy" title="Read more in our Privacy Policy" style={{color: "#FFF",textTransform: "uppercase",fontSize: "14px"}}>Read our Privacy Policy for more information</a></div>
        </CookieConsent>
      </div>
    );
  }
}

export default App;
import React, { Component } from 'react';
import fenceSandbags from '../../assets/fence_sandbags.png'
import plane from '../../assets/plane.png'
import smoke from '../../assets/smoke_isolated.png'
import cloud1 from '../../assets/new_cloud_1.png'
import cloud2 from '../../assets/new_cloud2.png'
import scrollIcon from '../../assets/scroll_icon.png'
import sticker from '../../assets/get_involved_sticker.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import seperator from '../../assets/section_break.png';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ParallaxImage from '../global-components/image';



class SectionOne extends Component {

  handleClick = () => {
    scroll.scrollTo(window.innerHeight * 1.45, {
      duration: 1500
    });
  };

  render() {
    return (

      <div className="container" id="section-one">
        <ParallaxBanner className="parallax"
          layers={[
            {
              children: <div ><img id="smoke" src={smoke} alt="smoke" /></div>,
              amount: 0.1,
            },
            {
              children: <div ><img id="cloud-1" src={cloud1} alt="cloud-1" /></div>,
              amount: -0.2,
            },
            {
              children: <div ><img id="cloud-2" src={cloud2} alt="cloud-2" /></div>,
              amount: -0.2,
            },
            {
              children: <div><img id="plane" src={plane} alt="plane" /></div>,
              amount: -0.6,
            },
            {
              children: <ParallaxImage id={'sandbagsdiv'}></ParallaxImage>,
              amount: -0.25,
            },
            {
              children: <div className="seperator seperator-one">
              <img src={seperator} alt="seperator" />
            </div>,
            amount:-0.15,
            }
          ]}
          style={{
            height: '130vh',
          }}
        >
        </ParallaxBanner>
        <div id="main-text-container">
          <h1>WE'VE BUILT THE ONLY FLYING WW1 PLANE IN SCOTLAND -
            <br></br>A SOPWITH 1-1/2 STRUTTER.<br></br><br></br>
            AND WE'RE NOT STOPPING THERE.</h1>
          <a onClick={this.handleClick}><img id="scroll-icon-section-one" className="scroll-icon" src={scrollIcon} alt="scroll-down" /></a>
          <a href="https://localgiving.org/charity/savesophie/" title="Donate today!" target="_blank"><img id="sticker" src={sticker} alt="sticker" /></a>
          {/* <h5 id="sandbags-text">YOU ARE INVITED TO JOIN US ON OUR JOURNEY TO ESTABLISH THE </h5>
          <h5 id="chap-title">COMMUNITY HERITAGE AVIATION PROJECT</h5> */}
        
        </div>
      </div>


    );
  }
}

export default SectionOne;
import React, { Component } from 'react';
import imageTwo from '../../assets/image2.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import imageOne from '../../assets/image1.png'

class SectionTwo extends Component {
    
    render() {
        return (
            <div className="about-section-two">

                <div className="about-text-parallax-container">

                  <div id="about-info">
                        <div id="about-text" style={{width: "80vw"}}>
                        <p><strong>Version 1.1</strong>&nbsp;- Last updated&nbsp;<strong>16 June 2020</strong></p>
                        <p>The privacy of our website users is important to us and we take care to safeguard it. This policy explains how we use such personal information we may hold about you.</p>
                        <h2>IMPORTANT INFORMATION</h2>
                        <p><strong>Queries</strong></p>
                        <p>If you have any queries concerning your personal information or any questions on our use of your information, please contact <a href="mailto:hello@strutteraviation.com" style={{color: "#8AA5A7"}}>hello@strutteraviation.com</a>.</p>
                        <p><strong>Changes</strong></p>
                        <p>Our privacy policy is regularly reviewed to make sure that we continue to serve your privacy interests. We reserve the right to modify or otherwise update this privacy policy and these changes will be posted on our website. We encourage you to visit this page from time to time to ensure that you are aware of any changes we may have made.</p>
                        <h2>WHO ARE WE</h2>
                        <p><strong>Us</strong></p>
                        <p>In this Privacy Policy references to "we", "our" and "us" are to Aviation Preservation Society, Scotland, the organisation responsible for this website.</p>
                        <h2>COOKIES - WHAT INFORMATION WE COLLECT AND WHY</h2>
                        <p><strong>What are cookies?</strong></p>
                        <p>Cookies are small text files, which often include a unique identifier that is sent by a web server to your computer, mobile phone or any other internet enabled device when you visit a website. Cookies are widely used in order to make websites work efficiently and help provide us with business and marketing information to enable us to make informed decisions on the development of our service. We may collect information about your computer, including your IP address, operating system and browser type. This is statistical data about our users' browsing actions and patterns, and does not identify any individual.</p>
                        <p><i>Non-essential cookies</i> - These cookies help us deliver a better experience on our service, but you as the user have the right to opt out of these at any time. You can change your cookie settings for these cookies by modifying your browser preference below.</p>
                        <p><strong>Cookies used</strong></p>
                        <p>We only use non-essential cookies on this website. The following cookies may be sent to you (please note sizes may vary slightly):</p>

                        <div class="row-fluid marginTop marginBot">
                            <div class="span8 offset2">
                                <table class="table table-condensed table-responsive">
                                <thead>
                                    <tr><th></th><th>Cookie Name</th><th>Expiry Date</th><th>Size</th><th>Essential</th><th style={{width: "280px"}}>Purpose</th></tr>
                                </thead>
                                <tbody>
                                    
                                    <tr>
                                    <td>1</td>
                                    <td><span>_ga</span></td>
                                    <td>2 years from set/update</td>
                                    <td>30 B</td>
                                    <td>No</td>
                                    <td>Google Analytics Tracking cookie.<br/> - Used to distinguish users.</td>
                                    </tr>
                                    <tr>
                                    <td>2</td>
                                    <td><span>_gat</span></td>
                                    <td>1 minutes</td>
                                    <td>5 B</td>
                                    <td>No</td>
                                    <td>Google Analytics Tracking cookie.<br/> - Used to throttle request rate.</td>
                                    </tr>
                                    <tr>
                                    <td>3</td>
                                    <td><span>_gid</span></td>
                                    <td>24 hours</td>
                                    <td>31 B</td>
                                    <td>No</td>
                                    <td>Google Analytics Tracking cookie.<br/> - Used to distinguish users.</td>
                                    </tr>
                                    <tr>
                                    <td>4</td>
                                    <td><span>consentCookie</span></td>
                                    <td>1 year from set/update</td>
                                    <td>24 B / 27 B</td>
                                    <td>Yes</td>
                                    <td>Records whether or not you have accepted the use of non-essential cookies on our site.</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <p>Cookies (1), (2) &amp; (3) are tracking cookies installed by Google Analytics. These cookies are non-essential and you have the right to opt-out of these at any point in time. Cookie (4) is stored when the user implicitly agrees to have cookies stored on their machine, these are set in order to remember preference in regards to cookies being stored. Google Analytics stored data on a retention preiod of 14 Months.</p>

                        <p><strong>Information volunteered by you</strong></p>
                        <p>It is important you are aware of browser tracking. During use of the website, tracking information is collected and stored to monitor usage and to help further its development. The tracking, supplied and developed by Google, is by default active - if you choose not to take part, please click the 'Decline Cookies' button. However, please be aware that declining these cookies will hinder our development of this website in order to enhance your experience.</p>
                        <p><strong>IP addresses</strong></p>
                        <p>The information we collect via this website may also include your IP address (this is your computer&rsquo;s individual identification number that is assigned to your computer when connected to the Internet) which is automatically logged by our web-server. This information is collected by our website host in the United Kingdom and is processed to ensure our legitimate interests in maintaining a secure and reliable website.</p>
                        <h2>WHAT WE DO WITH YOUR INFORMATION</h2>
                        <p><strong>Compliance</strong></p>
                        <p>Any personal information we collect from this website will be used in accordance with the General Data Protection Regulation, Data Protection Bill and other applicable laws.</p>
                        <p><strong>Keeping you informed</strong></p>
                        <p>The IP addresses logged by our website server may be used by us to perform technical diagnosis of our website&rsquo;s performance on a statistical basis and to analyse traffic data (so as to gauge interest in our website).</p>
                        <p><strong>SSL Security</strong></p>
                        <p>We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input during your time on this website. It is important for you to protect against unauthorised access to your password and to your computer. Be sure to sign off/log off when finished using a shared computer.</p>
                        <p><strong>Law enforcement</strong></p>
                        <p>We reserve the right to cooperate with law enforcement officials in the investigation of alleged unlawful activities of our website users or relating to our website users. We will have no legal liability for such disclosures.&nbsp;Unless we suspect fraud, we do not use your IP address to identify you personally. We also reserve the right to disclose any personal data to third parties if we are under a duty to disclose or share personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions (as amended from time to time) or to protect the rights, property, or safety of Aviation Preservation Society, Scotland, our customers, or others. This includes exchanging information with other companies and organisations for the purpose of fraud protection and credit risk reduction.</p>
                        <h2>YOUR RIGHTS</h2>
                        <p>Under the General Data Protection Regulation (GDPR) and The Data Protection Bill you have several rights when it comes to your personal data.</p>
                        <p><strong>Updating your data</strong></p>
                        <p>Where we hold your personal data, we must ensure that is accurate and up-to-date, therefore please help us to fulfil this obligation by informing us at <a href="mailto:hello@strutteraviation.com" target="_blank" style={{color: "#8AA5A7"}}>hello@strutteraviation.com</a>, of any changes in your personal details. Where a request to rectify your data is submitted, we will ensure changes are made within 1 month of receiving the request.</p>
                        <p><strong>Removing your data</strong></p>
                        <p>You can ask us to update or remove your personal information by emailing <a href="mailto:hello@strutteraviation.com" target="_blank" style={{color: "#8AA5A7"}}>hello@strutteraviation.com</a>. Our team will have 1 month, where applicable, to facilitate such a request.</p>
                        <p><strong>Transferring and obtaining your data</strong></p>
                        <p>You also have the right to request a copy of any personal information we hold about you. To access your information, please email <a href="mailto:hello@strutteraviation.com" target="_blank" style={{color: "#8AA5A7"}}>hello@strutteraviation.com</a>. Our team will have 1 month to facilitate such a request.</p>
                        <p><strong>Object to Data Processing</strong></p>
                        <p>Where we process your data under legitimate interest, where applicable, you have the right to object to this processing. To submit this request, please email&nbsp;<a href="mailto:hello@strutteraviation.com" target="_blank" style={{color: "#8AA5A7"}}>hello@strutteraviation.com</a>.</p>
                        <p><strong>How long is your data held for?</strong></p>
                        <p>As part of our security monitoring activities we log activity at an IP Address level, which helps us identify unusual activity that may require investigation as part of bug fixing or suspected malicious activity. This information is required to help us fulfil our contractual obligations to you. Our Logs are securely maintained with limited, authorised access and are retained by our hosting service providers for up to 12 months after which they are permanently erased.</p>
                        <p><strong>More information</strong></p>
                        <p>Detailed information about your rights under the GDPR and the Data Protection Bill and can be found on the website of the United Kingdom Information Commissioner, the address of which is (as at the date of this Privacy Policy) <a href="https://ico.org.uk/" target="_blank" style={{color: "#8AA5A7"}}>ico.org.uk</a>. The telephone number of the Information Commissioner&rsquo;s Office is 01625 545 700 (or (44) 1625 545 700 if phoning from outside the United Kingdom.)</p>
                        <h2>OTHER WEBSITES</h2>
                        <p><strong>Hyper links</strong></p>
                        <p>This Privacy Policy only covers personal data collected via this website. While we carefully select the websites to which we link, we are not responsible for the privacy practices or the content of these websites.</p>
                        <p>Any thoughts shared by another website belong to that person(s) and are not necessarily the views of Aviation Preservation Society, Scotland.</p>
                        <h2>Disclosure of Personal Data</h2>
                        <p>We may share personal data with elected third parties in pursuit of our legitimate business interests including:</p>
                        <ul>
                        <li>Our suppliers for the purpose of administering our business. Our suppliers will process personal data in accordance with our instructions from time to time as &lsquo;data processors&rsquo; as defined in the GDPR and Data Protection Bill.</li>
                        <li>Analytics and search engine providers that assist us in the improvement and optimisation of our website as we have set out in this Privacy Policy.</li>
                        </ul>
                        <p>We may also disclose personal data to third parties:</p>
                        <ul>
                            <li>In the event that we sell or otherwise transfer our business or any part of it, in which case databases include personal data will be transferred to the buyer.</li>
                            <li>If we under a duty to disclose or share personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions (as amended from time to time) or to protect the rights, property, or safety of Aviation Preservation Society, Scotland, our customers, or others. This includes exchanging information with other companies and organisations for the purpose of fraud protection and credit risk reduction.</li>                         
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default SectionTwo;
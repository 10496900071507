import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import SectionTwo from '../components/about-components/section-two';
import SectionOne from '../components/about-components/section-one';
import SectionThree from '../components/about-components/section-three';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { withController } from 'react-scroll-parallax'; 
import PropTypes from 'prop-types'
import Footer from '../components/global-components/footer';
// import SectionFour from '../components/about-components/section-four';
import SectionFour from '../components/home-components/section-three';

class About extends Component {

  static propTypes = {
    parallaxController: PropTypes.object,
  };
  
  componentDidMount(){
    const { parallaxController } = this.props;
    parallaxController.update();
    document.title = 'Aviation Preservation Society of Scotland | Hanger 32';
  }

  render() {
    return (
      <Animated animationIn="fadeIn" isVisible={true}>
        <div>
          <SectionOne></SectionOne>
          <SectionTwo></SectionTwo>
          <SectionThree></SectionThree>
          <SectionFour></SectionFour>
        </div>
        <Footer></Footer>
      </Animated>
    );
  }
}

export default withController(About);

import React, { Component } from 'react';
import plane from '../../../assets/plane.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import { withController } from 'react-scroll-parallax';
import { Animated } from "react-animated-css";
import scrollIcon from '../../../assets/scroll_icon.png'
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import cursorIcon from '../../../assets/cursor_icon.png'
import seperator from '../../../assets/section_break.png';



class SlideTwo extends Component {

  constructor(props){
    super(props)
    this.state = {
      showText: false,
      isIE: this.props.isIE
    }

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > (window.innerHeight * 4) + window.innerHeight/0.8 && window.pageYOffset < window.innerHeight * 7) {
      this.setState({
        // showText:true
      })
    }
  }

  render() {

    var text;

    if(!this.state.showText){
     text = <p className="fade-in-fast">The Strutter was built utilising original plans and uses, where possible, materials which<br>
            </br>would have been available during the early 20th Century (with the
            exception of modern glues and fabric).<br></br><br></br> Click on a part of a the plane to find out more about the assembly.</p>
    }else{
      text = <div></div>
    }
    
    return (
      // <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
        <div id="slide-two-container" className="plane-container">
        <div className="slide-overlay"></div>
          <div className="fade-in-fast slide-text" id="slide-two-text">
            <h2>CONSTRUCTION</h2>
            {text}
          </div>
          {/* <div id="wings" className="slide-item fade-in">
            <img id="cursor-icon" src={cursorIcon} alt="cursor" />
            <p>WINGS</p>
          </div> */}
       {/* <img id="big-plane" src={plane} alt="plane" /> */}
       
          {/* <div className="scroll-container">
                   <a onClick={this.handleClick}><img id="section-two-scroll-icon" src={scrollIcon} alt="scroll-down" /></a>
                 </div> */}
                   <div className="seperator seperator-one">
          <img src={seperator} alt="seperator" />
        </div>
        </div>
      // </Animated>
    )
  };
}

export default SlideTwo;
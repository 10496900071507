import React, { Component } from 'react';
import logo from '../../assets/logo_colour2.png'
import twitter from '../../assets/twitter_icon.png'
import instagram from '../../assets/insta_icon.png'
import youtube from '../../assets/youtube_icon.png'
import facebook from '../../assets/facebook_icon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo1 from '../../assets/logo_awardsforall_v2.png'
import logo2 from '../../assets/logo_calahomes.png'
import logo3 from '../../assets/logo_heritagefund.png'
import logo4 from '../../assets/logo_mctaggartscott.png'
import logo5 from '../../assets/logo_tunnocks.png'
import logo6 from '../../assets/2020logo.png'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

class Footer extends Component {

  handleClick = () => {
    scroll.scrollToTop(100);
  };


  render() {
    return (
      <div className="footer-container">
        <div className="back-to-top"><a onClick={this.handleClick}>BACK TO TOP</a></div>
        <div className="sponsor-logos">
          <div className="logo-container"><a href="https://www.tnlcommunityfund.org.uk/funding/programmes/national-lottery-awards-for-all-scotland" title=""><img className="logo-image" src={logo1} alt="Awards For All" style={{width:220,marginTop:10}} /></a></div>
          <div className="logo-container"><a href="https://www.cala.co.uk/" title="Visit the Cala Homes website"><img className="logo-image" src={logo2} alt="Cala Homes" /></a></div>
          <div className="logo-container"><a href="https://www.heritagefund.org.uk" title="Visit the Heritage Fund website"><img className="logo-image" src={logo3} alt="Heritage Fund" /></a></div>
          <div className="logo-container"><a href="https://www.mactag.com" title="Visit the MacTaggart Scott website"><img className="logo-image" src={logo4} alt="MacTaggart Scott" style={{marginTop:25}} /></a></div>
          <div className="logo-container"><a href="http://www.tunnock.co.uk/" title="Visit the Tunnock's website"><img className="logo-image" src={logo5} alt="Tunnock's" /></a></div>
          <div className="logo-container"><a href="https://www.2020productions.com/" title="Visit 20/20 Productions website"><img className="logo-image" src={logo6} alt="20/20 Productions" /></a></div>
        </div>
        <div className="footer">
          <img id="footer-logo" src={logo} alt="Hangar 32" />
          <span className="line">
          <div className="footer-info"><p>Congalton Gardens<br/>North Berwick<br/>EH39 5JP</p></div>
          <div className="footer-info"><p><a href="mailto:hello@strutteraviation.com" title="Email Strutter Aviation">hello@strutteraviation.com</a></p></div>
          </span>
          {/* <ul><li></li><li></li><li></li></ul> */}
          <div><p><a href="/privacy-policy" title="View our Privacy Policy" style={{color: "#8AA5A7"}}>Privacy Policy</a><br/>Hangar 32 is a project by<br/>&copy; Aviation Preservation Society, Scotland<br/>Scottish Charity, No. SC048150</p>
          </div>
          <div id="nav-social">
              <a href="https://twitter.com/struttertherep?lang=en-gb" target="_blank" className="nav-link" to="/">
                <img className="" id="nav-twitter" src={twitter} alt="twitter" />
              </a>
              <a href="https://en-gb.facebook.com/strutterapss/" target="_blank" className="nav-link" to="/">
                <img className="" id="nav-facebook" src={facebook} alt="facebook" />
              </a>
            </div>
        </div>
      </div>
    );
  }
}

export default Footer;
import React, { Component } from 'react';
import SlideOne from './section-two-slides/slide-one';
import SlideTwo from './section-two-slides/slide-two';
import SlideThree from './section-two-slides/slide-three';
import SlideFour from './section-two-slides/slide-four';
import SlideFive from './section-two-slides/slide-five';
import seperator from '../../assets/section_break.png';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import scrollIcon from '../../assets/scroll_icon.png'
import plane from '../../assets/plane_withshadow_new.png'
import { Stickyroll } from '@stickyroll/stickyroll'
import cursorIcon from '../../assets/cursor_icon.png'
import disableScroll from 'disable-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import tailOne from '../../assets/tail_image1.png'
import tailTwo from '../../assets/tail_image2.png'
import engineOne from '../../assets/engine_image1.png'
import engineTwo from '../../assets/engine_image2.png'
import wingsOne from '../../assets/wings_image1.png'
import wingsTwo from '../../assets/wings_image2.png'
import fuselageOne from '../../assets/fuselage_image1.png'
import fuselageTwo from '../../assets/fuselage_image2.png'
import originOne from '../../assets/origin_image.png'
import purposeOne from '../../assets/purpose_image1.png'
import purposeTwo from '../../assets/purpose_image2.png'
import specsOne from '../../assets/specs.png'
import productionOne from '../../assets/production.png'
import richard from '../../assets/image_Richard_Bell-Davies.png'
import gunner from '../../assets/gunners_seat_image.png'
import royalBlind from '../../assets/royal_blind_logo.png'
import washingMachine from '../../assets/washing_machine_image.png'
import pilotSeat from '../../assets/pilots_seat_image.png'
import surgeon from '../../assets/orthopaedic_surgeon_image.png'


const { detect } = require('detect-browser');
const browser = detect();
var isIE;

if (browser.name === "ie") {
  isIE = true;
}

const slides = [
  <SlideOne isIE={isIE}></SlideOne>,
  <SlideTwo isIE={isIE}></SlideTwo>,
  <SlideThree isIE={isIE}></SlideThree>,
  <SlideFour isIE={isIE}></SlideFour>,
  <SlideFive isIE={isIE}></SlideFive>
]

class SectionTwo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slideNumber: 1,
      prevSlide: 1,
      active: "slide-link slide-link-active",
      slideOneShow: false,
      slideTwoShow: false,
      slideThreeShow: false,
      planeShow: false,
      showModal: "",
      isMobile: false,
      isIE: false
    }

    this.firstSlide = React.createRef();
    this.secondSlide = React.createRef();
    this.thirdSlide = React.createRef();
    this.fourthSlide = React.createRef();
    this.fifthSlide = React.createRef();
    this.changeSlide = this.changeSlide.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.displayInformation = this.displayInformation.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  componentDidMount() {
    if (browser.name === "ie") {
      console.log(browser)
      this.setState({
        isIE: true
      });
    }
    this.setState({
      prevSlide: this.firstSlide.current
    });
    if (window.innerHeight < 600 || window.innerWidth < 600 || isIE) {
      console.log("Mobile")
      console.log(window.innerHeight)
      console.log(window.innerWidth)
      this.setState({
        isMobile: true
      });
    } else {
      this.setState({
        isMobile: false
      });
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  //Update state on slide change
  changeSlide(ref) {
    var notActive = this.state.prevSlide
    notActive.className = "slide-link"
    this.setState({
      slideNumber: ref.current.id,
      prevSlide: ref.current
    });
    ref.current.className = "slide-link slide-link-active";
  }

  //Track which slide the user is on 
  handleScroll() {
    if (window.pageYOffset >= window.innerHeight * 1.3) {
      this.changeSlide(this.firstSlide)
      this.setState({
        planeShow: true
      })
    }
    if (window.pageYOffset > window.innerHeight * 2.3 && window.pageYOffset < window.innerHeight * 3.3) {
      this.setState({
        slideNumber: 2
      })
      this.changeSlide(this.secondSlide)
    }
    if (window.pageYOffset > window.innerHeight * 3.3 && window.pageYOffset < window.innerHeight * 4.3) {
      this.changeSlide(this.thirdSlide)
      this.setState({
        slideNumber: 3
      })
    }
    if (window.pageYOffset > window.innerHeight * 4.3 && window.pageYOffset < window.innerHeight * 5.3) {
      this.changeSlide(this.fourthSlide)
      this.setState({
        slideNumber: 4
      })
    }
    if (window.pageYOffset > window.innerHeight * 5.3) {
      this.changeSlide(this.fifthSlide)
      this.setState({
        planeShow: false,
        slideNumber: 5
      })
    }
  }

  //Scroll to corresponding slide
  navigate(e) {
    var id = e.currentTarget.id;
    var transitionDuration = 600;

    switch (id) {
      case '1':
        scroll.scrollTo(window.innerHeight * 1.7, {
          duration: transitionDuration
        });
        break;
      case '2':
        scroll.scrollTo(window.innerHeight * 2.7, {
          duration: transitionDuration
        });
        break;
      case '3':
        scroll.scrollTo(window.innerHeight * 3.7, {
          duration: transitionDuration
        });
        break;
      case '4':
        scroll.scrollTo(window.innerHeight * 4.7, {
          duration: transitionDuration
        });
        break;
      case '5':
        scroll.scrollTo(window.innerHeight * 5.7, {
          duration: transitionDuration
        });
        break;
      default:
    }
  }

  //Show modal
  displayInformation(e) {
    var id = e.currentTarget.id;
    var modalName = id + '-text'
    disableScroll.on()
    window.removeEventListener('scroll', this.handleScroll)
    if (this.state.showModal != modalName) {
      this.setState({
        showModal: modalName
      })
    }
    console.log(this.state.showModal)
  }

  //Close modal
  hideModal(e) {
    if (e.target.className != 'popup-text') {
      this.setState({
        showModal: ""
      })
      window.addEventListener('scroll', this.handleScroll);
      disableScroll.off()
    }
  }


  render() {
    var planeImage;
    var innerText;
    var planeClass;
    var mainSection;
    var textOne;
    var textTwo;
    var textThree;
    var textFour;
    var textFive;
    var popupContainer;
    var popupContainerLrg;

    if (this.state.isIE || this.state.isMobile) {
      popupContainer = "popup-container container-ie";
      popupContainerLrg = "popup-container container-ie-large"
    } else {
      popupContainer = "popup-container";
      popupContainerLrg = "popup-container";
    }

    //Pop-up information cards for slides
    textOne = <div className="fade-in-fast text-overlay">
      <div id="who" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>WHO BUILT IT?</p>
      </div>
      {this.state.showModal === "who-text" ? <div className={popupContainer}><div id="who-text" className="popup-text popup-text-no-images fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>WHO BUILT IT?</h2><p>The underlying organisation in charge of constructing the Strutter, is the APSS (Aviation Preservation Society Scotland) who have dedicated their time to hand-building authentic WW1 aircraft. The Strutter has taken 19 years to build and a large number of volunteers have contributed to its assembly.
      The APSS is looking to share their knowledge and skills by rolling out the aircraft construction, and future constructions, to the community as part of The Community Heritage Aviation Project (CHAP).
      </p></div></div></div> : null}
      <div id="what" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>OUR PLAN FOR THE FUTURE</p>
      </div>
      {this.state.showModal === "what-text" ? <div><div className={popupContainer}><div id="what-text" className="popup-text popup-text-no-images popup-text-large fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>OUR PLAN FOR THE FUTURE</h2>
        <ul>
          <li>Phase 1. For us to be able to offer the training and experience of heritage aircraft building that we want, our shed needs to be made fit for purpose. So our first phase will be to finish and fly the Strutter and to raise the money to upgrade our shed.
          </li>
          <br />
          <li>Phase 2. We will further develop and broaden our outreach programmes and take young people on to a series of carefully designed training courses. We will continue to build heritage aircraft.
          </li>
          <br />
          <li>Phase 3. Establish Hanger 32!
        </li>
        </ul>
        <div className="image-container"></div></div></div></div></div> : null}
      <div id="why" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>WHY THE STRUTTER?</p>
      </div>
      {this.state.showModal === "why-text" ? <div className={popupContainer}><div id="why-text" className="popup-text popup-text-no-images popup-text-large fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>WHY THE STRUTTER?</h2><p>Back in 1999/2000, Tim Rayner (LAA inspector) and Adam Smith (then Curator of National Museum of Flight) recognised that the National Museum of Flight at East Fortune had no WW1 aircraft despite being a WW1 airfield. They asked the APSS volunteers if they'd be up for building a little known but significant aircraft for the museum. They'd never built an aircraft before, so of course they agreed.<br></br><br></br>
        The Strutter was significant because it was the first allied aircraft that could fire through the arc of the propeller, it was the first with tail trimming and first with airbrakes.
      </p></div></div></div> : null}
      <div id="project" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>WHAT IS THE COMMUNITY HERITAGE AVIATION PROJECT?</p>
      </div>
      {this.state.showModal === "project-text" ? <div className={popupContainerLrg}><div id="project-text" className="popup-text popup-text-no-images popup-text-largest fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>WHAT IS THE COMMUNITY HERITAGE AVIATION PROJECT?</h2><p>This is the overall project of building a series of flying WW1 aircraft, with the help of community volunteers. The end goal is showcasing these planes within a visitor attraction (Hangar32).</p>
        <p> We've learned so much over the 20 years that we've been building the Strutter and we want to pass it on. </p>

        <p>Aviation in all its forms is incredible and has been the centre of our lives seemingly forever. If you're looking for a career, aviation offers everything. Engineering, military flying, civilian flying. In fact, pilots for flying airliners are in enormous demand. </p>

        <p>Our own special interest is heritage aviation. The early pioneering years of flight. In particular and we're interested in the role of East Lothian in the defence of Edinburgh and the county during the Great War.</p>

        <p>So we're building WW1 aircraft and we're inviting the community to join us either as visitors, volunteers or as trainees. We will visit schools and colleges, run aviation projects and offer training and experience.</p>
      </div></div></div> : null}

      <div id="hangar" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>WHAT IS HANGAR 32?</p>
      </div>
      {this.state.showModal === "hangar-text" ? <div className={popupContainer}><div id="hangar-text" className="popup-text popup-text-no-images popup-text-large popup-text-large fade-in-fast"><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><div><h2>WHAT IS HANGAR 32?</h2><p>Imagine a fully active WW1 airfield with officer's mess, hangars and workshops
      It's our dream to establish a WW1 airfield as a tribute to the men and women who defended East Lothian and Edinburgh from the Zeppelin and the U-boat.
      <br></br><br></br>
        We also want to celebrate the work done by the Royal Navy and Royal Naval Air Service to develop aircraft carriers in the Forth.
      <br></br><br></br>
        Hangar 32 will be an immersive new visitor attraction in East Lothian. It will showcase the talents of the volunteers of the APSS and will tell the untold story of East Lothian and its people in the Great War.
      <br></br><br></br>
        We will also develop digital interpretations and create a unique competitive simulator experience.
      </p></div></div></div> : null}
    </div>
    textTwo = <div className="fade-in-fast text-overlay">
      <div id="wings" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>WINGS</p>
      </div>
      {this.state.showModal === "wings-text" ? <div className={popupContainer}><div id="wings-text" className="popup-text popup-text-large fade-in-fast"><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><div><h2>WINGS</h2><p>26 individually handmade ribs on top the top with and 24 on the bottom wing. Whole set up made rigid by piano wire and  aeroshaped flying and landing wires under tension. Wires made at Brunton Wireworks in Musselburgh as they were in WW1!</p></div><div className="image-container"><img src={wingsOne} className="popup-image-one" alt="popup-image-one" /><img src={wingsTwo} className="popup-image-two" alt="popup-image-two" /></div></div></div> : null}
      <div id="engine" onClick={this.displayInformation} className="slide-item">
        <p>ENGINE</p><p></p>
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
      </div>
      {this.state.showModal === "engine-text" ? <div className={popupContainer}><div id="engine-text" className="popup-text fade-in-fast"><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><div><h2>ENGINE</h2><p>Originally powered by a French engine (the best available at the time) the 110 HP Clerget rotary engine. Rotary engines were (shall we say) interesting. The propeller was fixed to the engine and the entire engine spun! To add to the interest, the engine was either on or off. There was no throttle. This made flying a rotary engined aircraft a considerable challenge and more pilots were killed in flying accidents than killed in action. We have decided it would be safer to use a radial engine which was bought from Rotec in Australia.</p></div><div className="image-container"><img src={engineOne} className="popup-image-one" alt="popup-image-one" /><img src={engineTwo} className="popup-image-two" alt="popup-image-two" /></div></div></div> : null}
      <div id="tail" onClick={this.displayInformation} className="slide-item">
        <p>TAIL</p><p></p>
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
      </div>
      {this.state.showModal === "tail-text" ? <div className={popupContainer}><div id="tail-text" className="popup-text popup-text-smaller fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>TAIL</h2><p>One of the first aircraft to use trimming technology. As the bullets and oil ran out, the balance of the aircraft altered. This meant the pilot would have to push the joystick forward to keep the aircraft in trim. This could be tiring. The introduction of a wheel to change the trim from the cockpit was very welcome to the harassed pilot.</p></div><div className="image-container"><img src={tailOne} className="popup-image-one" alt="popup-image-one" /><img src={tailTwo} className="popup-image-two" alt="popup-image-two" /></div></div></div> : null}
      <div id="propeller" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>PROPELLER</p>
      </div>
      {this.state.showModal === "propeller-text" ? <div className={popupContainer}><div id="propeller-text" className="popup-text popup-text-no-images fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>PROPELLER</h2><p>Original carved from a single piece of wood and extremely heavy. Modern propeller made of laminated wood. Stronger and lighter.</p></div></div></div> : null}
      <div id="covering" onClick={this.displayInformation} className="slide-item">
        <p>COVERING</p><p></p>
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
      </div>
      {this.state.showModal === "covering-text" ? <div className={popupContainer}><div id="covering-text" className="popup-text popup-text-no-images fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>COVERING</h2><p>Originally covered in doped Irish Linen, our concession to modernity is to use Ceconite. This is glued to the frame and then heat shrunk with an iron. It is sewed onto the wing ribs...a most time-consuming exercise!</p></div></div></div> : null}
      {this.state.showModal === "plans-text" ? <div className={popupContainer}><div id="wings-text" className="popup-text fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>TAIL</h2><p>here GOES some TEXT</p></div></div></div> : null}
      <div id="fuselage" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>FUSELAGE</p>
      </div>
      {this.state.showModal === "fuselage-text" ? <div className={popupContainer}><div id="wings-text" className="popup-text popup-text-smaller fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>FUSELAGE</h2><p>Four long strips of wood (longerons) with short trusses all held together by piano wire under stress (not glue)!</p></div><div className="image-container"><img src={fuselageOne} className="popup-image-one" alt="popup-image-one" /><img src={fuselageTwo} className="popup-image-two" alt="popup-image-two" /></div></div></div> : null}
    </div>

    textThree = <div className="fade-in-fast text-overlay">
      <div id="origin" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>ORIGIN</p>
      </div>
      {this.state.showModal === "origin-text" ? <div className={popupContainer}><div id="origin-text" className="popup-text popup-text-no-images popup-text-large fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>ORIGIN</h2><p>In December 1914, the Sopwith Aviation Company designed a small, two-seat biplane powered by an 80 horsepower (60 kW) Gnome rotary engine, which became known as the "Sigrist Bus" after Fred Sigrist, the Sopwith works manager. <br></br><br></br> The Sigrist Bus first flew on 5 June 1915 and although it set a new British altitude record on the day of its first flight, only one was built, serving as a company runabout.
      The Sigrist Bus formed the basis for a new, larger, fighter aircraft, the Sopwith LCT (Land Clerget Tractor), designed by Herbert Smith and powered by a 110 horsepower (82 kW) Clerget engine. Like the Sigrist Bus, each of the upper wings (there was no true centre section) was connected to the fuselage by a pair of short (half) struts and a pair of longer struts, forming a "W" when viewed from the front; this giving rise to the aircraft's popular nickname of the ​11⁄2 Strutter. The first prototype was ready in mid-December 1915, undergoing official testing in January 1916.
      </p></div></div></div> : null}
      <div id="production" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>PRODUCTION</p>
      </div>
      {this.state.showModal === "production-text" ? <div className={popupContainer}><div id="production-text" className="popup-text popup-text-smallest fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>PRODUCTION</h2><p>1,439 built for British forces.<br></br> 4,200 to 4,500 built in France.<br></br> TOTAL: nearly 6,000 units. </p></div><div className="image-container"><img src={productionOne} className="popup-image-one" alt="popup-image-one" /></div></div></div> : null}
      <div id="purpose" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>PURPOSE</p>
      </div>
      {this.state.showModal === "purpose-text" ? <div className={popupContainer}><div id="purpose-text" className="popup-text popup-text-smaller fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>PURPOSE</h2><p>Built as a multi-purpose aircraft to be both a single seat light bomber and a two-seater fighter, it was the first British aircraft to feature synchronised firing machine guns. it was initially utilised as a bomber escort and became an effective scout platform.</p></div><div className="image-container"><img src={purposeOne} className="popup-image-one" alt="popup-image-one" /><img src={purposeTwo} className="popup-image-one" alt="popup-image-one" /></div></div></div> : null}
      <div id="rbd" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p> VICE ADMIRAL <br></br>RICHARD BELL-DAVIES</p>
      </div>
      {this.state.showModal === "rbd-text" ? <div className={popupContainerLrg}><div id="rbd-text" className="popup-text popup-text-largest fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>VICE ADMIRAL<br></br>RICHARD BELL-DAVIES</h2><p>Vice Admiral Richard Bell-Davies, VC, CB, DSO, AFC (19 May 1886 – 26 February 1966), was a senior Royal Navy commander, naval aviator, and a First World War recipient of the Victoria Cross, the highest award for gallantry in the face of the enemy that can be awarded to British and Commonwealth forces.
        This was the first combat search and rescue by aircraft in history. Like the search and rescue efforts of the future, Davies' action sprang from the fervent desire to keep a compatriot from capture or death at the hands of the enemy; unlike most of those future efforts, it was a one-man impromptu action that succeeded because of a peculiarity in construction of his aircraft.
        In his biography, Sailor of the Air, he said that the Strutter was his favourite aircraft.
        The Strutter arrived at East Fortune in December 1916.
        </p></div><div className="image-container"><img src={richard} className="popup-image-richard" alt="popup-image-richard" /></div></div></div> : null}
      <div id="specs" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>SPECS</p>
      </div>
      {this.state.showModal === "specs-text" ? <div className={popupContainer}><div id="specs-text" className="popup-text popup-text-smaller fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>SPECS</h2><p>
        Crew: 		1 or 2<br></br>
        Speed: 		102 miles per hour (164 kph/89 knots)<br></br>
        Range: 		351 miles (565 km, 305 nautical miles)<br></br>
        Armament: 	1 x 7.7mm Vickers machine gun (fixed, forward-firing mounting)<br></br>
        1 x 7.7mm Lewis Machine Gun (trainable mounting in rear cockpit position)<br></br>
        Optional 4 x 25lb drop bombs
</p></div><div className="image-container"><img src={specsOne} className="popup-image-one" alt="popup-image-one" /></div></div></div> : null}
    </div>

    textFour = <div className="fade-in-fast text-overlay">
      <div id="surgeon" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>ORTHOPAEDIC SURGEON</p>
      </div>
      {this.state.showModal === "surgeon-text" ? <div className={popupContainer}><div id="surgeon-text" className="popup-text popup-text-no-images  fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>ORTHOPAEDIC SURGEON</h2><p>Our replica Vickers and Lewis gun was built by an orthopaedic surgeon in his garage.

</p></div></div></div> : null}
      <div id="pilots" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>PILOT SEAT</p>
      </div>
      {this.state.showModal === "pilots-text" ? <div className={popupContainer}><div id="pilots-text" className="popup-text popup-text-smallest popup-text-no-images fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>PILOT SEAT</h2><p>The original pilot seat was wicker! This one was made by the Royal Blind School in Edinburgh.</p></div></div></div> : null}
      <div id="pistols" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>PISTOLS</p>
      </div>
      {this.state.showModal === "pistols-text" ? <div className={popupContainer}><div id="pistols-text" className="popup-text popup-text-smaller fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>PISTOLS</h2><p>The crew carried pistols so that when the aircraft catches fire, they can either jump or shoot themselves. That's a decision for a 19 year old!</p></div><div className="image-container"><img src={purposeOne} className="popup-image-one" alt="popup-image-one" /><img src={purposeTwo} className="popup-image-one" alt="popup-image-one" /></div></div></div> : null}
      <div id="gunners" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>GUNNERS SEAT</p>
      </div>
      {this.state.showModal === "gunners-text" ? <div className={popupContainer}><div id="gunners-text" className="popup-text popup-text-smallest fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>GUNNERS SEAT</h2><p>The observer/gunners seat was a three legged milking stool.
  </p></div><div className="image-container"><img src={gunner} className="popup-image-one" alt="popup-image-one" /></div></div></div> : null}
      <div id="first" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>FIRST AIRCRAFT TO...</p>
      </div>
      {this.state.showModal === "first-text" ? <div className={popupContainer}><div id="first-text" className="popup-text popup-text-no-images fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>FIRST BRITISH AIRCRAFT TO...</h2><p>Fire through the arc of its propeller.
        <br></br><br></br>
        First with airbrakes and first with trim control. </p></div><div className="image-container"></div></div></div> : null}
      <div id="washing" onClick={this.displayInformation} className="slide-item">
        <img className="cursor-icon" src={cursorIcon} alt="cursor" />
        <p>WASHING MACHINE</p>
      </div>
      {this.state.showModal === "washing-text" ? <div className={popupContainer}><div id="washing-text" className="popup-text popup-text-smaller fade-in-fast"><div><FontAwesomeIcon onClick={this.hideModal} className="modal-close" icon={faTimes} /><h2>WASHING MACHINE</h2><p>At one stage washing machine plumbing had to be used as temporary tubing!
</p></div><div className="image-container"><img src={washingMachine} className="popup-image-one" alt="popup-image-one" /></div></div></div> : null}
    </div>

    //Render clickable link overlays for the respective slides
    if (this.state.slideNumber == 1) {
      innerText = textOne
    } else if (this.state.slideNumber == 2) {
      innerText = textTwo
    } else if (this.state.slideNumber == 3) {
      innerText = textThree
    } else if (this.state.slideNumber == 4) {
      innerText = textFour
    } else {
      innerText = <div></div>
    }

    if (this.state.slideNumber > 5) {
      planeClass = "absolute fade-in"
    } else {
      planeClass = "absolute"
    }

    if (this.state.planeShow) {
      planeImage = <div className="plane-div"><img id="big-plane" src={plane} alt="plane" className="absolute" />
        {innerText}
      </div>
    } else {
      planeImage = <div></div>
    }

    //Mobile slides
    if (this.state.isMobile) {
      mainSection =
        <div className="sticky-container">
          <div className="overflow-hidden">
            <div className="plane-div"><img id="big-plane" src={plane} alt="plane" className="absolute" />
              {textOne}
            </div>
            <SlideOne isIE={this.state.isIE} isMobile={this.state.isMobile} ></SlideOne>
            <div className="plane-div"><img id="big-plane" src={plane} alt="plane" className="absolute" />
              {textTwo}
            </div>
            <SlideTwo isIE={this.state.isIE} ></SlideTwo>
            <div className="plane-div"><img id="big-plane" src={plane} alt="plane" className="absolute" />
              {textThree}
            </div>
            <SlideThree isIE={this.state.isIE}></SlideThree>
            <div className="plane-div"><img id="big-plane" src={plane} alt="plane" className="absolute" />
              {textFour}
            </div>
            <SlideFour isIE={this.state.isIE}></SlideFour>
            <SlideFive isIE={this.state.isIE}></SlideFive>
            <div className="slide-links hidden">
              <div id='1' onClick={this.goToSlide} ref={this.firstSlide} className="slide-link slide-link-active"></div>
              <div id='2' ref={this.secondSlide} className="slide-link"></div>
              <div id='3' ref={this.thirdSlide} className="slide-link"></div>
              <div id='4' ref={this.fourthSlide} className="slide-link"></div>
              <div id='5' ref={this.fifthSlide} className="slide-link"></div>
            </div>
          </div>
        </div>
    } else {
      //Non-mobile slides
      mainSection =
        <Stickyroll pages={slides} factor={1}>
          {({ pageIndex }) => {
            return (
              <div className="sticky-container">
                {planeImage}
                {slides[pageIndex]}
                <div className="slide-links">
                  <div id='1' onClick={this.navigate} ref={this.firstSlide} className="slide-link slide-link-active"></div>
                  <div id='2' onClick={this.navigate} ref={this.secondSlide} className="slide-link"></div>
                  <div id='3' onClick={this.navigate} ref={this.thirdSlide} className="slide-link"></div>
                  <div id='4' onClick={this.navigate} ref={this.fourthSlide} className="slide-link"></div>
                  <div id='5' onClick={this.navigate} ref={this.fifthSlide} className="slide-link"></div>
                </div>
              </div>
            );
          }}
        </Stickyroll>
    }

    return (
      <div>
        {mainSection}
      </div>
    );
  }
}


export default SectionTwo;




import React, { Component } from 'react';
import planeOne from '../../assets/background_plane.png';
import planeTwo from '../../assets/foreground_plane.png';
import cloud1 from '../../assets/new_cloud_1.png';
import cloud2 from '../../assets/new_cloud2.png';
import { ParallaxBanner } from 'react-scroll-parallax';
import seperator from '../../assets/section_break.png';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import scrollIcon from '../../assets/scroll_icon.png';
import Image from '../global-components/image';
import logo from '../../assets/logo_colour.png';


class SectionOne extends Component {
    handleClick = () => {
        scroll.scrollTo(window.innerHeight + window.innerHeight / 100 * 40, {
            duration: 1800
        });
    };

    render() {
        return (

            <div className="about-section-one">
                <div id="about-container">
                </div>
                <ParallaxBanner className="parallax"
                    layers={[

                        {
                            children: <img src={planeOne} id="plane-one" alt="plane-one" />,
                            amount: -0.3,
                        },
                        {
                            children: <img src={planeTwo} id="plane-two" alt="plane-two" />,
                            amount: -0.7,
                        },
                        {
                            children: <div ><img id="cloud-1" src={cloud1} alt="cloud-1" /></div>,
                            amount: -0.2,
                        },
                        {
                            children: <div ><img id="cloud-2" src={cloud2} alt="cloud-2" /></div>,
                            amount: -0.2,
                        },
                        {
                            children: <div id="about-sandbags"></div>,
                            amount: -0.2,
                        },
                        {
                            children: <div id="about-headings">
                                 <img id="apss-logo" src={logo} alt="logo" />
                                <h1>Privacy Policy</h1>
                                <a onClick={this.handleClick}><img id="scroll-icon-about" className="scroll-icon" src={scrollIcon} alt="scroll-down" /></a>
                            </div>,
                            amount: 0.0
                        },
                    ]}
                    style={{
                        height: '140vh',
                    }}
                >
                </ParallaxBanner>
                <div className="seperator seperator-about">
                    <img src={seperator} alt="seperator" />
                </div>
            </div>
        );
    }
}

export default SectionOne;

import 'airbnb-js-shims/target/es2015';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './animate.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "typeface-pathway-gothic-one";
// import "typeface-lato";




ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

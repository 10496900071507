import React, { Component } from 'react';
import plane from '../../../assets/plane.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import { withController } from 'react-scroll-parallax';
import { Animated } from "react-animated-css";
import scrollIcon from '../../../assets/scroll_icon.png'
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import seperator from '../../../assets/section_break.png';


class SlideTwo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isIE: this.props.isIE
    }
  }

  render() {
    return (
      <div id="slide-three-container" className="plane-container">
      <div className="seperator seperator-four">
          <img src={seperator} alt="seperator" />
        </div>
      <div className="slide-overlay"></div>
        <div className="fade-in-fast slide-text" id="slide-three-text">
          <h2>THE HISTORY</h2>
          <p>Over the Western Front, the Fokker Eindecker was creating havoc with its ability to fire a machine gun through the arc of the propeller. Although it was not the most agile aircraft, the allies struggled against it and were desperate for an aircraft to compete in the air and so Tommy Sopwith set out to create a competitive fighter that had a machine gun synchronised with the propeller.
</p>
        </div>
        <ParallaxBanner
          layers={[
            {
              // children: <img onLoad={this.handleLoad} id="big-plane" src={plane} alt="plane"/>,
              amount: -0.0,
            },
          ]}
          style={{
            height: '100vh',
          }}
        >
        </ParallaxBanner>
        {/* <div className="scroll-container">
                   <a onClick={this.handleClick}><img id="section-two-scroll-icon" src={scrollIcon} alt="scroll-down" /></a>
                 </div> */}
        
      </div>
      // {/* </Animated> */}
    )
  };
}

export default SlideTwo;
import React, { Component } from 'react';
import imageOne from '../../assets/image3.png'
import imageTwo from '../../assets/image4.png'
import imageThree from '../../assets/image5.png'
import imageFour from '../../assets/image6.png'
import imageFive from '../../assets/newabout_1.png'
import imageSix from '../../assets/newabout_2.png'
import imageSeven from '../../assets/newabout_3.png'
import imageEight from '../../assets/newabout_4.png'
import { ParallaxBanner } from 'react-scroll-parallax';

class SectionThree extends Component {
    render() {
        return (
            <div>
                <div className="about-section-three">
                    <div className="about-parallax-container left-padding">
                        <img src={imageOne} className="about-image" id="about-image-three" alt="image-one" />
                        <img src={imageTwo} className="about-image" id="about-image-four" alt="image-two" />
                        <img src={imageThree} className="about-image" id="about-image-five" alt="image-three" />
                        <img src={imageFour} className="about-image" id="about-image-six" alt="image-four" />
                    </div>
                    <div className="about-text-parallax-container">
                        <div id="about-info">
                            <div id="about-text" className="about-text-section-three">
                                <h2>OUR HISTORY</h2>
                                <p>Formed in 1973 to help in the establishment of the National Museum of Flight at East Fortune, we were asked in 2000 to build a flying exhibit for the museum. We researched and we fundraised and now possess the only flying WW1 fighter in Scotland.
                                        </p>
                                <h2>OUR IMPACT</h2>
                                <p>Over 100 members. Over 1000 visitors have been to our workshop and learned about early aviation and the defence of Scotland from Zeppelins and submarines.<br></br>
                                    <br></br>
                                    We have entertained groups from schools, colleges, community groups and have given talks at schools, colleges and institutions.
                                            <br></br><br></br>
                                    We have provided training for international students as well as trainees and apprentices in industry.
                                        </p>
                                <h2>OUR GOALS</h2>
                                <ul>
                                    <li>To continue building significant WW1 aircraft</li>
                                    <li>To open our workshop to young and old, disadvantaged and marginalised and inspire them to immerse themselves in engineering, aviation and the history of the defence of East Lothian</li>
                                    <li>To make an impact in the community</li>
                                    <li>In coming years to establish a WW1 Living Heritage Visitor Attraction commemorating the men and women who defended the county in the Great War</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-section-three-two">
                    <div className="about-text-parallax-container">
                        <div id="about-info">
                            <div id="about-text">
                            <h2>OUR COLLECTION</h2>
                                <p>We have an extensive collection of magazine articles and photographs, collected over many years.</p>
                                <p>We can research into specific topics as requested by APSS project teams and anybody who needs to utilise our expertise.</p>
                                <p>We have an excellent selection of material open to anyone to explore.</p>
                                <h2>RADIO AND RADAR</h2>
                                <p>We have a unique collection of historic radio and radar equipment and love to tell the fascinating story of its role in the defence of Scotland and the Edinburgh area.</p>
                                <p>We have very close links with Leonardo (previously Ferranti) and have been given a fascinating array of ground and airborne radar units.</p>
                                <p>If you want to get your hands on some historic technology and see a mock up of a Lancaster radio bay or examine early Gyro Gunsights, come and talk our team of enthusiasts.</p>
                                <h2>GLIDER</h2>
                                <p>We have restored and fly one of the last Slingsby T53 gliders in the country. A team of APSS members fly out of Portmoak in Fife as often as weather permits. New members more than welcome. Just apply to <a href="mailto:alan@strutteraviation.com" style={{color: "#FFF"}} title="Apply to become a member of the team">alan@strutteraviation.com</a> to become an APSS member and enjoy some of the most affordable flying available in Scotland.</p>
                                
                                {/* <p>We have an extensive collection of magazine articles and photographs, collected over many years.</p>
                                <p>We can research into specific topics as requested by APSS project teams and anybody who needs to utilise our expertise.</p>
                                <p>We have an excellent selection of material open to anyone to explore.</p> */}
                                <h3 className="additional">For additional <span class="line">resources and related media<a href="https://gerardgbl.wixsite.com/strutter" target="_blank" className="footlocker-button">FOOTLOCKER</a></span></h3>
                            </div>
                            </div>
                        </div>
                        <div className="about-parallax-container right-padding">
                            <img src={imageFive} className="about-image" id="about-image-seven" alt="image-two" />
                            <img src={imageSix} className="about-image" id="about-image-eight" alt="image-one" />
                            <img src={imageSeven} className="about-image" id="about-image-nine" alt="image-two" />
                            <img src={imageEight} className="about-image" id="about-image-ten" alt="image-one" />
                        </div>
                    </div>
                </div>
                )
            }
        }
        
export default SectionThree;
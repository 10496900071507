import React, { Component } from 'react';
import plane from '../../../assets/plane.png'
import { ParallaxBanner } from 'react-scroll-parallax';
import { withController } from 'react-scroll-parallax';
import { Animated } from "react-animated-css";
import scrollIcon from '../../../assets/scroll_icon.png'
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import seperator from '../../../assets/section_break.png';
import newPlane from '../../../assets/SopwithPup_image2.png';
import newPlaneHidden from '../../../assets/pup_greyedout_withquestionmark.png';
import icon from '../../../assets/icon_questionmark.png'
import paper from '../../../assets/paper6.png'


class SlideFive extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isIE: this.props.isIE,
      isPupVisible: false
    }

    this.togglePup = this.togglePup.bind(this);
  }

  handleClick = () => {
    scroll.scrollTo(window.innerHeight * 7.3, {
      duration: 1500
    });
  };

  togglePup(){
    if(!this.state.togglePup){
    this.setState({
      isPupVisible:true
    })
  }
  }

  // handleClick = () => {
  //   scroll.scrollTo(window.innerHeight * 3, {
  //     duration: 300
  //   });
  // };


  render() {
    var pupImage;
    var pupInfo;

    if (this.state.isPupVisible) {
      pupImage = <img onClick={this.togglePup} src={newPlane} className="pup-visible" alt="newPlane" />
      pupInfo = <div id="whats-next-info"><h2>SOPWITH 'PUP'</h2><div className="pup-paper"><p>The forerunner of the famous Sopwith Camel, the Pup was also a significant aircraft in the development of the Aircraft Carrier and many (perilous) trials were conducted in the Forth.</p>
      </div></div>
    } else {
      pupImage = <div className="pup-div"><img onClick={this.togglePup} src={newPlaneHidden} className="pup-hidden" alt="newPlaneHidden" /></div>
      pupInfo = <div id="whats-next"> <h3>CLICK TO FIND OUT WHAT WE'RE BUILDING NEXT</h3></div>
    }

    return (
      // <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
      <div id="slide-five-container" className="plane-container">
        <div className="clearfix"></div>
      <div className="slide-overlay"></div>
        <div className="fade-in-fast slide-text" id="slide-five-text">
          <h2>NEXT PROJECT</h2>
          <p>While we set out to establish a WW1 airfield (Hangar32), we are going to continue building WW1 aircrafts as part of the Community Heritage Aviation Project</p>
        </div>
        {pupInfo}
        {pupImage}
        <p className="slide-bottom-text fade-in-fast">You can help to make this dream a reality, by volunteering your time or making a charitable donation.</p>
        <div className="scroll-icon-container">
          <a onClick={this.handleClick}><img id="scroll-icon-slide-five" className="scroll-icon" src={scrollIcon} alt="scroll-down"/></a>
        </div>
        <div className="seperator seperator-two">
          <img src={seperator} alt="seperator" />
        </div>
      </div>

      // </Animated>
    )
  };
}

export default SlideFive;
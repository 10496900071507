import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import SectionTwo from '../components/privacy-components/section-two';
import SectionOne from '../components/privacy-components/section-one';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { withController } from 'react-scroll-parallax'; 
import PropTypes from 'prop-types'
import Footer from '../components/global-components/footer';

class Privacypolicy extends Component {

  static propTypes = {
    parallaxController: PropTypes.object,
  };
  
  componentDidMount(){
    const { parallaxController } = this.props;
    parallaxController.update();
    document.title = 'Privacy Policy | Hanger 32';
  }

  render() {
    return (
      <Animated animationIn="fadeIn" isVisible={true}>
        <div>
          <SectionOne></SectionOne>
          <SectionTwo></SectionTwo>
        </div>
        <Footer></Footer>
      </Animated>
    );
  }
}

export default withController(Privacypolicy);
